import partnerOne from "../../assets/images/partner-1.png";
import partnerTwo from "../../assets/images/partner-2.png";
import { Markets } from "./markets";
import { Roles } from "./roles";
// import { ProfileImageShapes } from "./profileImageShapes";
//import { Titles } from "./titles";
const DEFAULT_COVER = require("../../assets/images/about-profile-default.png");

export const Event = {
  eventName: "Hacktober",
  eventDescription: "[Some Event Details Here .........]",
  additionalDetails: "[Add Event Additional Details Here ........]",
  startDate: "Start Date",
  endDate: "End Date",
  nextYear: 2024,
  announcements: [
    {
      id: 1,
      text: "We are aware wordle is experiencing some issues. We are working to get it back up as soon as possible. Thank you for your patience!",
    },
  ],
  timeLineAndPrizes: [
    {
      title: "timeline-title-1",
      description: "timeline and prizes description - 1",
    },
    {
      title: "timeline-title-2",
      description: "timeline and prizes description - 2",
    },
    {
      title: "timeline-title-3",
      description: "timeline and prizes description - 3",
    },
    {
      title: "timeline-title-4",
      description: "timeline and prizes description - 4",
    },
  ],
  faqSections: [
    {
      name: "About",
      sectionData: [
        {
          title: "What is Hacktober?",
          body: [
            {
              itemText:
                "Information Security and Governance is proud to present Slalom's sixth annual Hacktober celebration, a global Slalom initiative dedicated to building cybersecurity awareness in entertaining and engaging ways! Created by Reedhima Mandlik and inspired by National Cybersecurity Awareness month every October in the U.S., Hacktober makes cybersecurity awareness fun with spooky security-themed events. Hacktober's mission is to weave cybersecurity into the fabric of Slalom´s culture and help cultivate a security-first mindset. Together, we are all a vital part of the InfoSec team! Have questions? Contact zenith@slalom.com  for more information.",
            },
          ],
        },
        {
          title: "What is this platform?",
          body: [
            {
              itemText:
                "This platform may look familiar to you if you participated in events utilizing the Zenith platform. The Zenith platform blends cybersecurity with Slalom events to bring a fun, unique way to interact with our ERG and ID&E groups!",
            },
          ],
        },
        {
          title: "What is Cybersecurity Awareness month?",
          body: [
            {
              itemText:
                "National Cybersecurity Awareness month has been around since 2004, helping citizens protect themselves online as our technology, and threats to that technology, become more sophisticated and interwoven in our daily lives. The Cybersecurity and Infrastructure Security Agency (CISA) is the lead on a collaborative effort between government and industry that raises nationwide cybersecurity awareness. Dedicated to this effort is a special public-private partnership led by the National Cybersecurity Alliance (NCA). Slalom has been participating for the past five years through our own internal initiative, Hacktober.",
            },
          ],
        },
        {
          title:
            "I need accommodation due to neurodiversity, visual & audio impairment, etc. What should I do?",
          body: [
            {
              itemText:
                "Please email zenith@slalom.com  and include the accommodations you need to enable your participation.",
            },
          ],
        },
        {
          title:
            "Can I invite clients or people external to Slalom to this event?",
          body: [
            {
              itemText:
                "No. Do not share any events or recordings externally! Hacktober is exclusively built for Slalom employees.",
            },
          ],
        },
        {
          title: "Know the Code?",
          body: [
            {
              itemText: `know-the-code-title-1\
                

Always make sure to review your client's security requirements before you start your next engagement. Don't know where to find them? Ask your engagement lead for more info.

 

know-the-code-title-2\
              

Before you save or transfer client data onto Slalom systems, check that it's secure, sanitized, and the way it's being handled is approved. Contact your Account Executive for any exceptions or approvals.

 


know-the-code-title-3\
              

Company and client-sensitive data should never be exposed to the public internet. Always make sure to keep your client data safe!

 

know-the-code-title-4\
              

Don't use personal tools to access, collect, transfer, or store client-sensitive data for collaboration, email, or online storage.

 

know-the-code-title-5\
              

Slalom or client-issued laptops must be in a secure location at all times and not in a locked car somewhere.

 

know-the-code-title-6\
              

Always return client-issued laptops at the end of every project.

 

know-the-code-title-7\
              

If you've observed a client-sensitive data loss or recent security incident, please contact your account executive or Slalom Information Security at security@slalom.com .`,
            },
          ],
        },
        {
          title: "InfoSec Survival Tips?",
          body: [
            {
              itemText: `InfoSec: Survival Tip #1\
 
  
 
survival-tip-title-1 
 
When you step away from your desk, don't forget to lock your computer screen. If you're out running errands, don't leave your computer in your car (even in your trunk!). And if you're traveling, we recommend you store your Slalom laptop in the hotel safe. There's no such thing as too careful when protecting your Slalom or client-issued computer!


InfoSec: Survival Tip #2\

 
 
survival-tip-title-2 
 
Don't leave confidential paperwork on your desk, and always use a secure shredder when you're done with a physical document that contains client information, PII, PHI, or private information.


InfoSec: Survival Tip #3\


 
survival-tip-title-3 
 
We're all responsible for protecting our workspace and our data. If a person tries to tailgate you into the office and you're unsure if they're with Slalom, please contact your front desk.`,
            },
          ],
        },
      ],
    },
    {
      name: "Activities",
      sectionData: [
        {
          title: "What if I can't attend? Will the sessions be recorded?",
          body: [
            {
              itemText:
                "Every session will be recorded and uploaded into Workday. You will be able to find the link to all recordings on our Past Events tab on the Events page.  Please note that event recordings are entered manually at the end of each week, and are uploaded by volunteers, so please be patient. Event codes will work until the end of Hacktober.",
            },
          ],
        },
        // {
        //   title: "What is a Digital Code Hunt?",
        //   body: [
        //     {
        //       itemText:
        //         "The Digital Code Hunt is a feature dedicated to testing your security coding knowledge! The goal is to catch the flaw in the code. Once a day, you can choose a challenge from any level -- the more points, the harder the challenge! Solve them all correctly to win a fantastic surprise. You can only solve one challenge per day. Be sure to use our ChatGPT Securclippy to help you along your journey!",
        //     },
        //   ],
        // },
        {
          title: "What is a Question of the Day?",
          body: [
            {
              itemText:
                "The Question of the Day is a daily quiz during Hacktober that, if answered correctly, will earn you points. The questions refresh every 24 hours, which is unique to this event, so be sure to check back every business day for a chance to earn points!",
            },
          ],
        },
        {
          title: "Are all the events global and open to everyone?",
          body: [
            {
              itemText:
                "Yes -- all events are virtual and the time for each event is reflected in your time zone!",
            },
          ],
        },
        {
          title: "How do I sign up for an event?",
          body: [
            {
              itemText:
                "Review the events calendar on the Events page and select “Add to Calendar” for the events you would like to attend. If you see a “Join Now” button instead of the “Add to Calendar” button, that means that event is currently taking place. Click that button to be taken directly to that call! If you have any issues, please email hacktober@slalom.com .",
            },
          ],
        },
      ],
    },
    {
      name: "Points & Prizes",
      sectionData: [
        {
          title: "How do you earn points for Hacktober?",
          body: [
            {
              itemText:
                "There are many ways to earn points. You can earn points by answering the Question of the Day, attending events, completing activities, participating our hackathon, finding hidden points around the platform, and completing activities listed under Other Ways to Earn Points.",
            },
          ],
        },
        {
          title:
            "I did an activity and my points were not added right away. Why?",
          body: [
            {
              itemText:
                "All point-earning activities aside from the QOTD, Code Hunt, and Events are currently a manual process, which takes time. Manual point entries will be refreshed every Friday. If your points are not updated by the following week, please contact zenith@slalom.com .",
            },
          ],
        },
        {
          title:
            "Who do I contact if I think my point total is tallied incorrectly?",
          body: [
            {
              itemText:
                "If you feel that you have an incorrect point total, please reach out to zenith@slalom.com  and our development team will investigate! Please note that the Other ways to Earn Points activities have points added manually, so we appreciate your patience as those get added.",
            },
          ],
        },
        {
          title: "What prizes are available?",
          body: [
            {
              itemText: `At the end of the month, the Securyeti will tally all the points acquired and award prizes for the following categories:\
                
              
Top 10 Global Winners: If you are in the top 10 at the end of Hacktober, congratulations! You will win a Hacktober branded Tarana Backpack Cooler!\


Global Top Winner: If you are number 1 on the global leaderboard at the end of Hacktober, congratulations! You will win an exclusive branded Hacktober jacket.\


Local Top Winners: If you are number 1 in your market leaderboard at the end of Hacktober, congratulations! You will win a branded YETI insulated mug.`,
            },
          ],
        },
        {
          title:
            "If I watch an event recording, can I still use the code to earn points?",
          body: [
            {
              itemText:
                "Yes -- event codes will only work until the end of Hacktober, however, so please be sure to input your code by then.",
            },
          ],
        },
        {
          title: "When will winners be announced?",
          body: [
            {
              itemText:
                "Winners will be announced by mid November, and prizes will be distributed by December due to potential shipping delays. Please look out for an email if you've won!",
            },
          ],
        },
        // {
        //   title: "Is the bug minigame worth points?",
        //   body: [
        //     {
        //       itemText:
        //         "This game is not worth points -- it's just a fun little treat for our Hacktober users!",
        //     },
        //   ],
        // },
      ],
    },
    {
      name: "Contact",
      sectionData: [
        {
          title: "Who can I contact if I am having issues with the site?",
          body: [
            {
              itemText:
                "Please email zenith@slalom.com  with screenshots and a thorough description of the issue, and someone from our team will work to troubleshoot.",
            },
          ],
        },
        {
          title: "Who can I contact regarding Hacktober questions?",
          body: [
            {
              itemText:
                "Reach out to zenith@slalom.com  for any questions that aren't answered here!",
            },
          ],
        },
        {
          title: "I have a question about an event. Who can I contact?",
          body: [
            {
              itemText:
                "Reach out to the event host or email zenith@slalom.com  for more clarification about an event!",
            },
          ],
        },
        {
          title:
            "Who developed this events platform, and how can I get involved?",
          body: [
            {
              itemText:
                "A wonderful team of volunteers brought this platform to life. You can check them out by visiting the About page. Interested in participating in the next events platform build? Sign up via the About page!",
            },
          ],
        },
        {
          title:
            "I'd love to use the Zenith Platform for the work I'm doing. Who can I reach out to?",
          body: [
            {
              itemText:
                "Please sign up for our waitlist via our Microsoft Form zenith-platform-microsoft-form",
            },
          ],
        },
      ],
    },
  ],
  partnersList: [
    {
      name: "Microsoft",
      link: "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/Microsoft.aspx",
      image: null,
    },
  ],
  aboutPage: {
    title: "Welcome to Slalom Women's History Month",
    eventIntroText:
      "Information Security and Governance is proud to present our 6th annual Hacktober! Created by Reedhima Mandlik, Hacktober is a way to make cybersecurity engaging and fun. Hacktober has since grown to be a Slalom Women-led initiative in partnership with the Information Security and Governance team, and evolved into Project Zenith to help connect cybersecurity and Slalom events at a global scale. Our mission is to weave cybersecurity into the fabric of Slalom´s culture and help cultivate a security-first mindset. Together, we are all a vital part of the InfoSec team!",
    noteFromPrismaticaTeamHeader: `WELCOME TO SLALOM HACKTOBER!`,
    noteFromPrismaticaTeam: `Information Security and Governance is proud to present Slalom's fifth annual Hacktober celebration, a global Slalom initiative dedicated to building cybersecurity awareness in entertaining and engaging ways! Created by Reedhima Mandlik and inspired by National Cybersecurity Awareness month every October in the U.S., Hacktober makes cybersecurity awareness fun with spooky security-themed events. Hacktober has since grown to be in partnership with Slalom Women technologists. Our mission is to weave cybersecurity into the fabric of Slalom´s culture and help cultivate a security-first mindset. Together, we are all a vital part of the InfoSec team!
    Have questions? Contact zenith@slalom.com  for more information.`,
    noteFromInfoSecTeamHeader: `A Note From The InfoSec Team`,
    noteFromInfoSecTeam: `Information Security and Governance is proud to unveil the first ever Slalom Events Platform, dedicated to bringing Slalom together in fun, secure, and meaningful ways! The Events Platform first began as Hacktober in 2019. Created by Reedhima Mandlik, Hacktober is a way to make cybersecurity engaging and fun. Hacktober has since grown to be a Slalom Women-led initiative in partnership with the Information Security and Governance team, and evolved into the Events Platform to help connect cybersecurity and Slalom Events at a global scale. Our mission is to weave cybersecurity into the fabric of Slalom´s culture and help cultivate a security-first mindset. Together, we are all a vital part of the InfoSec team!`,
    aboutEventsFeedbackText: `We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.`,
    aboutEventsJoinText: `Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!`,
    aboutEventsBugText: `Please report all bugs through our link below!`,
    aboutSponsors: [
      {
        name: "Partner 1",
        link: "",
        image: partnerOne,
        blurb: "",
      },
      {
        name: "1Password",
        link: "",
        image: partnerTwo,
        blurb: "",
      },
      // {
      //   name: "1Password",
      //   link: "https://twodegrees1.sharepoint.com/teams/InformationSecurityGovernance/SitePages/1Password.aspx",
      //   image:
      //     "https://storage.googleapis.com/tech-tools-list-test.appspot.com/89680316-dac5-46dc-9f1f-761957da73c2/tools_1Password_logo.png",
      //   blurb: "",
      // },
      // {
      //   name: "Git Guardian",
      //   link: "https://twodegrees1.sharepoint.com/teams/GlobalAlliances",
      //   image:
      //     "https://dataconnectors.com/hubfs/Imported_Blog_Media/gitguardian-logotype-horizontal-white-background.jpg",
      //   blurb: "",
      // },
      // {
      //   name: "Microsoft",
      //   link: "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/Microsoft.aspx",
      //   image:
      //     "https://blogs.microsoft.com/wp-content/uploads/prod/2012/08/8867.Microsoft_5F00_Logo_2D00_for_2D00_screen.jpg",
      //   blurb: "",
      // },
      // {
      //   name: "AWS",
      //   link: "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/AWS.aspx",
      //   image:
      //     "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/1920px-Amazon_Web_Services_Logo.svg.png",
      //   blurb: "",
      // },
      // {
      //   name: "Snowflake",
      //   link: "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/Snowflake(1).aspx",
      //   image:
      //     "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Snowflake_Logo.svg/2560px-Snowflake_Logo.svg.png",
      //   blurb: "",
      // },
      // {
      //   name: "Google",
      //   link: "https://twodegrees1.sharepoint.com/teams/GoogleCloudGo-To-MarketContentCenter/SitePages/Home.aspx?OR=Teams-HL&CT=1666106594340&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA5MDQwMDcxMiIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D",
      //   image:
      //     "https://upload.wikimedia.org/wikipedia/commons/5/51/Google_Cloud_logo.svg",
      //   blurb: "",
      // },
      // {
      //   name: "Salesforce",
      //   link: "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/Global-Salesforce.aspx?source=https%3A%2F%2Ftwodegrees1.sharepoint.com%2Fteams%2FGlobalAlliances%2FSitePages%2FForms%2FByAuthor.aspx",
      //   image:
      //     "https://www.salesforce.com/news/wp-content/uploads/sites/3/2021/05/Salesforce-logo.jpg?resize=2048,1152",
      //   blurb: "",
      // },
      // {
      //   name: "Snyk",
      //   link: "https://twodegrees1.sharepoint.com/teams/GlobalAlliances",
      //   image:
      //     "https://res.cloudinary.com/snyk/image/upload/v1537345894/press-kit/brand/logo-black.png",
      //   blurb: "",
      // },
    ],
    aboutTeams: {
      Leadership: [
        {
          fullName: "John Smith",
          role: Roles.ld,
          market: Markets.boston,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.qualityLead,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.sponsor,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.leadArchitect,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.leadArchitect,
          market: Markets.vancouver,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.leadDataArchitect,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.leadARArchitect,
          market: Markets.sydney,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.leadSolutionArchitect,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.leadArchitect,
          market: Markets.dallas,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.srArchitect,
          market: Markets.charlotte,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.smeA,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.witLeadership,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.communicationsLead,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: `${Roles.leadership.founder}, ${Roles.leadership.programDirector}`,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
      ],
      Ownership: [
        {
          fullName: "John Smith",
          role: Roles.solutionOwner,
          market: Markets.siliconValley,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.solutionOwner,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.solutionAnalyst,
          market: Markets.losAngeles,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.solutionOwner,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
      ],
      Management: [
        {
          fullName: "John Smith",
          role: Roles.assistantPM,
          market: Markets.toronto,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.pm,
          market: Markets.phoenix,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.assistantPM,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.pm,
          market: Markets.london,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
      ],
      InfoSec: [
        {
          fullName: "John Smith",
          role: Roles.infoSec,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.infoSec,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.infoSec,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.infoSec,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.infoSec,
          market: Markets.miami,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.infoSec,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.infoSec,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.infoSec,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
      ],
      Design: [
        {
          fullName: "John Smith",
          role: Roles.srDesigner,
          market: Markets.toronto,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.securyetiDesigner,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.designer,
          market: Markets.houston,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.designer,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.designer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.srDesigner,
          market: Markets.atlanta,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.srVisualDesigner,
          market: Markets.atlanta,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
      ],
      Development: [
        {
          fullName: "John Smith",
          role: Roles.dataEngineer,
          market: Markets.boston,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.dallas,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.dataEngineer,
          market: Markets.minneapolis,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.qualityEngineer,
          market: Markets.boston,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.dallas,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.houston,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.qualityEngineer,
          market: Markets.nyc,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.arDeveloper,
          market: Markets.sydney,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.vancouver,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.nyc,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.atlanta,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.codeHuntDeveloper,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.dataEngineer,
          market: Markets.siliconValley,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.dataEngineer,
          market: Markets.philadelphia,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.developer,
          market: Markets.boston,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
        {
          fullName: "John Smith",
          role: Roles.qualityEngineer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team-member.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him",
        },
      ],
    },
  },
  winnersPage: {
    title: "Welcome to data privacy week",
    section1Title: "Thank you for Participating!",
    section1Description:
      "Information Security and Governance is proud to present Slalom's fifth annual Hacktober celebration, a global Slalom initiative dedicated to building cybersecurity awareness in entertaining and engaging ways! Created by Reedhima Mandlik and inspired by National Cybersecurity Awareness month every October in the U.S., Hacktober makes cybersecurity awareness fun with spooky security-themed events. Hacktober has since grown to be in partnership with Slalom Women technologists. Our mission is to weave cybersecurity into the fabric of Slalom's culture and help cultivate a security-first mindset. Together, we are all a vital part of the InfoSec team!",
    section1DescriptionNote: `Have questions? Contact zenith@slalom.com  for more information.`,
    section1DescriptionNote2: `Answers for the code hunt challenges can be found `,
    section1DescriptionNote2Link: `https://twodegrees1.sharepoint.com/:f:/t/EventsPlatform/Eq0NL_vAShlIjow2YN5tt_ABJqks_va8MJOjXRy4kK-D-A?e=iZrmnx`,
    feedbackTitle: "Got Feedback?",
    feedbackText:
      "We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.",
    joinTitle: "Join the Events Platform Build Team!",
    joinText:
      "Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!",
    bugTitle: "Caught a bug?",
    bugText: `Please report all bugs through our link below!`,
    secureLegendsTitle: "1st Place",
    secureLegendsText: "Insert text here",
    noteFromPrismaticaTeamHeader: `A Note From The Prismatica Team`,
    noteFromPrismaticaTeam:
      "Thank you for attending the dozens of Prismatica events this year, discovering our hidden SecurYeti, and participating in events in your community! Your participation this month has brought Slalom closer together and made us a more secure fun place to work.",
    noteFromInfoSecTeamHeader: `A Note From The InfoSec Team`,
    noteFromInfoSecTeam:
      "Curious which event will be hosted on the Events Platform next? Look out for the SecurYeti this October as Hacktober begins! Interested in being part of the next Platform build? Sign up below! If you have feedback on our platform, we would love to hear from you below as well. Thanks, and happy PRIDE!",
  },
  wrapPage: {
    title: "Thank you for participating!",
    message: `Thank you for participating in Slalom's sixth Hacktober celebration! Your participation brings Slalom one step closer to our 2030 strategic initiatives, and helps keep Slalom safe. Together, we are all a vital part of the InfoSec team! `,
    message2: `The code hunt challenge has been closed to enable tallying winners.
    `,
    message3:
      "We are currently tallying up points, please check back on November 10, 2023 to find out who won!",
    viewRecordingLink:
      "https://www.myworkday.com/slalom/d/inst/15$399912/23455$296.htmld",
    joinEventsPlatformTeamLink:
      "https://sparkthink.slalom.com/survey/s/f1ba0e98-34e7-4c32-a97e-34f0f7db2661",
    feedbackEmailAddress: "mailto:zenith@slalom.com",
    sharePointSite:
      "https://twodegrees1.sharepoint.com/teams/IandD/SitePages/PRISM.aspx",
  },
  preLaunchPage: {
    message: `Event specific pre-launch message here: Lorem ipsum dolor sit amet,
    consectetur adipiscing elit. Adipiscing auctor rhoncus, massa
    condimentum nibh amet, pulvinar.`,
    spotifyPlayList: "",
    videoLink: "",
    lineup: {
      1: {
        date: "DAY 1 - MAY 8TH",
        speakers: [
          "Kyle Battle",
          "Ryan McCreedy",
          "Summer Zhang",
          "CJ Gonzalez",
          "Jodi Hill",
          "Spenser Davison",
          "Josh Dix",
          "Alley Lyles-Jenkins",
          "Steve Hering",
          "Sabrina Voeller",
          "Lauren Scott",
          "Jen Macias",
          "Danielle Sheffler",
          "Ayman Samamry",
        ],
      },
      2: {
        date: "DAY 2 - MAY 9TH",
        speakers: [
          "Bermon Painter",
          "Nicole Frederick",
          "William Benn",
          "Sophie Kim",
          "Betsy Cohen",
          "Dora Garnett",
          "Kiran Maanickam",
          "Teresa Davies",
          "Emily Salamander",
          "Meagan Breidert",
          "Stevie Borne",
          "Adam Thies",
          "Arif Kabir",
          "Dan Mackett",
          "Mike Kennard",
          "Becky Jo Tuell-Simpson",
          "Marte Leisy",
          "Shweta Shidhore",
          "Tassia Huq",
        ],
      },
      3: {
        date: "DAY 3 - MAY 10TH",
        speakers: [
          "Martin Gardner",
          "Matt Collinge",
          "Nicole Owuor",
          "Saurabh Bhargava",
          "Alice Leong",
          "Janeen Osselborn",
          "Rene Putz",
          "Sam McClanahan",
          "Vinayak Nagarajan",
          "Kristi Brown",
          "Sireesha Gyara",
          "Dij Thulasi",
          "Ken Rosso",
        ],
      },
      4: {
        date: "DAY 4 - MAY 11TH",
        speakers: [
          "Julius Popoola",
          "Sören Karnstädt",
          "Ramona Proksch",
          "Brian McDaniel",
          "Ronin Sharma",
          "Tim Letscher",
          "Alana Kunkel",
          "Avery Green",
          "Karl Jackson",
          "Danielle Funston",
          "Erica Banks",
          "Ashley Pace",
          "Johanna DeYoung",
          "Richard Winston",
          "Anthony Marquardt",
          "Lisa Pappas",
          "Ankeeta Lal",
          "Ray Wang",
        ],
      },
    },
  },
};
